import React from 'react'
import moment from "moment"
import StorageManager from "./lib/StorageManager"
import TaskTable from "./components/TaskTable";
import TaskList from "./components/TaskList";

export default class App extends React.Component {

    constructor(props) {
        super(props);

        const day = StorageManager.get("day") || moment().format("DD.MM.YYYY");
        const days = StorageManager.get("days", []);
        let tasks = [];

        if (tasks.length === 0) {
            let dayFromStorage = days.find(({date}) => date === day);
            if (dayFromStorage) {
                tasks = dayFromStorage.tasks;
            }
        }

        this.state = {
            day: day,
            tasks: tasks,
            days: days,
            showOverview: false
        };

        this.interval = null;
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            this.forceUpdate();
        }, 1000);

        setInterval(async () => {
            StorageManager.set("day", this.state.day);
            await this.saveDay(this.state.day, this.state.tasks);
            //StorageManager.set("tasks", this.state.tasks);
            StorageManager.set("days", this.state.days);
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    onToggleOverview(event, state) {
        this.setState({
            showOverview: state !== undefined ? state : !this.state.showOverview
        });
    }

    getTotal() {
        const duration = moment.duration();
        this.state.tasks.forEach((task) => {
            const start = moment(task.start);
            const end = task.end ? moment(task.end) : moment();
            duration.add(end.diff(start));
        });
        return duration;
    }

    onTasksUpdate(tasks) {
        this.setState({tasks});
    }

    onTaskAdd(item) {
        this.setState({
            tasks: this.state.tasks.concat([item])
        });
    }

    onTaskRemove(task, index) {
        const tasks = this.state.tasks.concat([]);
        tasks.splice(index, 1);
        this.setState({
            tasks
        });
    }

    saveDay(date, tasks) {
        return new Promise((resolve) => {
            let days = this.state.days.concat([]);
            let day = days.find((day) => day.date === date);

            if (day) {
                days[days.indexOf(day)] = {
                    date: date,
                    tasks: tasks
                };
            } else {
                days.push({
                    date: this.state.day,
                    tasks: this.state.tasks
                });
            }

            this.setState({days}, () => {
                resolve(days);
            });
        });
    }

    onDayChange(event) {
        this.saveDay(this.state.day, this.state.tasks);
        let days = this.state.days;
        let tasks = [];

        const currentDay = days.find(({date}) => date === event.target.value);
        if (currentDay) {
            tasks = currentDay.tasks;
        }

        this.setState({
            day: event.target.value,
            tasks: tasks
        });
    }

    async onNewDay() {
        await this.saveDay(this.state.day, this.state.tasks);

        this.setState({
            day: moment().format("DD.MM.YYYY"),
            tasks: []
        });
    }

    render() {
        const today = moment().format("DD.MM.YYYY");
        return (
            <div className="time-wrapper">
                <div className="card mb-2">
                    <div className="card-body text-primary bg-dark">
                        <h5 className="mb-0">Time Tracking</h5>
                    </div>
                    <div className="form-group">
                        <select className="form-control form-control-sm" value={this.state.day}
                                onChange={this.onDayChange.bind(this)}>
                            {this.state.days.map(({date}, index) =>
                                (<option key={index} value={date}>{date}</option>)
                            )}
                            {this.state.days.find(({date}) => date === today) ? null : (
                                <option value={today}>{today}</option>)}
                        </select>
                    </div>
                </div>
                {this.state.showOverview ?
                    <TaskTable tasks={this.state.tasks} getTotal={this.getTotal.bind(this)}/> :
                    <TaskList tasks={this.state.tasks}
                              onTasksUpdate={this.onTasksUpdate.bind(this)}
                              onTaskAdd={this.onTaskAdd.bind(this)}
                              onTaskRemove={this.onTaskRemove.bind(this)}
                              getTotal={this.getTotal.bind(this)}
                    />}
                <div className="btn-group w-100 mb-2">
                    <button type="button" className="btn btn-secondary" onClick={this.onToggleOverview.bind(this)}>
                        <i className="fas fa-random"/>
                    </button>
                    <button type="button" className="btn btn-success" onClick={this.onNewDay.bind(this)}>
                        <i className="fas fa-sync"/>
                    </button>
                </div>
            </div>
        );
    }
}
